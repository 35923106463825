$font-family-sans-serif: 'Ruslan Display', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-secondary: 'Peachy Keen JF', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;


// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #ff0606;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $red;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start container-max-widths
//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px,
//        xxl: 1320px
//) !default;
// scss-docs-end container-max-widths

@import '~bootstrap/scss/bootstrap';
