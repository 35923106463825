.btn {
  border-radius: 0;
  font-family: $font-family-secondary;
  color: $white;
  border-bottom: solid 3px rgb(111,14,14);
  background-color: $primary;

  &:hover {
    background: none;
    color: $white;
  }
}
